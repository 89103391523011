:root {
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */
    
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    
     /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --base-font-family: OpenSans;
    --alternative-font-family: Roboto, Helvetica, Arial, sans-serif;
    
    /* change settings for headings */
    --header-bg-color: white;
    --header-padding: 0;
    
    /* change colors for topmenu */
    --topmenu-bg: var(--link-color);
    --topmenu-color: white;
    --topmenu-li-a-padding: 10px 15px;

    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 14px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.318;
    --scale-factor-xs: 1.118;
    
    /*Colors & Backgrounds*/
    --text-color: #444;
    --heading-color: var(--link-color);
    --link-color: #7F4909;
    --link-hover-color: #000;
    --alternative-color: #ff0000;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/OpenSans.css";
@import "/fontmodules/Roboto.css";
/*@import "/cssmodules/animate.css";*/


/* General bg-colors
---------------------------------------------------------*/

.maincolor {
    background-color: var(--link-color);
}

.white {
    background-color:white;
}

.lightgray {
    background-color:#eee;
}

.darkgray {
    background-color:#222;
}


/* Header
---------------------------------------------------------*/
header {
    background:var(--header-bg-color);
    text-transform:none;
    padding:var(--header-padding);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
}

header .container {
    padding:0 0.5em;
}

header a {
    color:white;
}

.topmenu {
    border-top:#673a04 solid 1px;
}

.menu li {
    margin:0;
}

.menu li a {
    padding:var(--topmenu-li-a-padding);
    color:white;
    text-transform:uppercase;
    font-weight:500;
}

.menu li a:hover, .menu .active a {
    background: var(--topmenu-bg);
    color:#e8be8c;
}

.menu .dropdown ul {
    background:var(--topmenu-bg);
    z-index:100;
}

.menu li:hover ul {
    margin-left:0;
}

.menu .dropdown li a {
    padding:var(--topmenu-li-a-padding);
    color:#fff;
    border-right:none;
}

.menu .dropdown li a:hover, .menu .dropdown .active a {
    background:var(--topmenu-bg);
    color:var(--topmenu-color);
}

.menu .sub-menu-items li a {
    margin:0;
}

.logo {
    padding:0;
}

.logo a {
    font-size:0;
}


/* bildspel
---------------------------------------------------------*/

.intro-content {
    position:absolute;
    top:0;
    width:100%;
}

.intro-content .intro {
    position:relative;
    display:flex;
    align-items: center;
    width:100%;
    height:calc(100vh - 169px);
}

.intro-content .intro .container {
    text-align:center;
    max-width:600px;
    width:100%;
    margin:0 auto;
    background:rgba(0,0,0,0.5);
    color:white;
    padding:0.5em;
}

.intro-content .intro .container h1 {
    color:#e8be8c;
}

.start-slider {
    position:relative;
}

.slick {
    position: relative;
    margin-bottom: 0;
}

.slick-track {
    display: flex;
    flex-direction: row;
}   

.start-slider .slick, .start-slider .slick-slide, .start-slider .slick-substitute > div {
    height:100%;
    min-height: calc(100vh - 169px);
}

.slick-slide, .slick-substitute > div {
    background-size: cover;
    background-position: center center;
}

.slick-slider {
    margin:0 auto;
    position: relative;
}

.start-slider .slick-slide {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
} 

.slick-title {
    text-align:center;
    color:white;
    font-size: calc(1 * var(--scale-factor-s)rem);
}

.slick-title h2 {
    display:block;
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    color:white;
    font-weight:700;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    margin-bottom:0.5em;

}

.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:100;
}

.slick-prev {
    left:50px;
    z-index:100;
}

.slick-next, .slick-prev {
    opacity:1;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    transition: 0.8s ease;
    -o-transition: 0.8s ease;
    -webkit-transition: 0.8s ease;
   background:rgba(0,0,0,0.7);
    padding:1.5em;
    font-family: Roboto;
    line-height:normal;
}

.slick-next:hover:before, .slick-prev:hover:before {
    background:white;
    color:black;
}

.slick-next:before {
    content:"»";
}

.slick-prev:before {
    content:"«";
}

.slick-dots {
    width:auto;
    bottom:50%;
    right:2em;
    z-index:100;
    
}

.slick-dots li {
    display:block;
    margin:0.5em 0;
}

.slick-dots li button:before {
    font-size:0rem;
    opacity:1.0;
    color: #000;
}

.slick-dots li.slick-active button:before {
    background:#000;
    display:block;
    border-radius:360px;
}

.slick-dots li {
    border:#000 solid 1px;
    border-radius:360px;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}


.down svg.fa-icon {
    animation: bounce 2s infinite;
    animation-iteration-count: 2;
    fill: #E8BE8C;
    width:44px;
    height:44px;
}

.down svg.fa-icon:hover {
    fill:black;
}




/* Content-startpage
---------------------------------------------------------*/
.block-2  {
    padding:2em 0;
}

.startnews {
    padding:3.5em 0;
}
@media (min-width: var(--breakpoint-s)) {
.news-item {
    display:flex !important;
    height:400px;
}
}

.news-item .inner-content {
    padding:2em;
}

.news-item .inner-content h3 {
    font-size: calc(.8 * var(--scale-factor) * var(--scale-factor)rem);
    color:black;
}

.slick-news .news-img {
    position:relative;
}

.news-img .slick-next {
    top:auto;
    bottom:20px;
    right:0;
}

.news-img .slick-prev  {
    top:auto;
    bottom:20px;
    left:0;
    right:71px !important;
    text-align:right
}

.news-item .button {
    background:white;
    border:#000 solid 1px;
    margin:1.5em 0 0 0;
    color:black;
}

.facebook-feed {
    margin-top:0;
    margin-bottom:0;
}

.block-4 {
    padding:2em 0;
}

.block-4 .slick, .block-4 .slick-slide {
    height:100%;
    min-height: 400px;
}



/* listing items
---------------------------------------------------------*/
.listing-item {
    margin-bottom:1.5em;
}

.listing-item p:first-of-type {
    padding-top:0;
    margin-top:0;
}

.listing-item h2 {
    margin-bottom:0;
}

.pagination {
}

.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
    padding: 4px 6px;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: var(--link-color);
}

.pagination ul li.active a {
    color:white;
}

.pagination ul li a {
    text-decoration: none;
}
    
/* Content
---------------------------------------------------------*/

.content.margin > h1, .content.margin > .art-img {
    margin-left:0 ;
}

.content h1 {
    margin-bottom:0;
}

.art-content p:first-of-type {
    margin-top:0;
}

/* Contact
---------------------------------------------------------*/

body.kontakt {
    background:#f5f5f5;
}

.contact-block {
    box-shadow: 0px 0px 0px 1px #ececec;
}

.contact-block-l, .contact-block-2 {
    padding: var(--padding);
}

.contact-block-2 > div {
    border-bottom:#eee solid 1px;
    padding:1.5em 0;
}

.contact-block-2 .contactinfo p {
    margin:0;
}

.contact-block-2>div a {
    text-decoration:none;
}

figure {
    margin:0;
}

.top-img {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
    height:20vh;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
} 

    

/* Footer
---------------------------------------------------------*/

footer {
    color:white;
}

footer h4 {
    color:white;
    font-size:1.3rem;
    letter-spacing:0;
}

footer a {
    color:white;
    text-decoration:none;
}

footer svg.fa-icon {
    fill:white;
}

footer svg.fa-icon:hover {
    fill: var(--link-hover-color);
}

footer .button {
    margin:0;
    border:#b7b7b7 solid 1px;
    border-radius:0px;
    display:block;
    text-align:left;
}

footer .button:hover {
    background:#000;
    border:#000 solid 1px;
    color:white;
}

footer .social-bottom svg.fa-icon {
    width:32px;
    height:32px;
}

footer .social-bottom {
    margin-top:1.5em;
}


/*Google maps*/
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}
 
/*Intendit footer*/
.created-by {
    background:#100F0F;
    border-top:#151414 solid 1px;
    padding:0.5em 0;
}

.created-by .container, .intenditfooter {
    padding:0;
    margin:0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
    color:#565656;
}

.intenditfooter >* {
    margin: var(--margin);
}

/* sticky footer */
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%; /* IE fix */
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin:0;
}

footer .house {
    margin-top:1em;
}


/* general
---------------------------------------------------------*/

.boltform textarea {
    min-height:120px;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
    color:#222;
}

button[type=submit], input[type=submit] {
    margin:0;
    background:var(--link-color);
    border:var(--link-color) solid 1px;
}

button[type=submit]:hover, input[type=submit]:hover {
    background: var(--link-hover-color);
    border:var(--link-hover-color) solid 1px;
}


img {
    max-width: 100%;
    height: auto;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.logotyper img {
    width:auto !important;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height:100%;
}

body {
    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--alternative-font-family);
    letter-spacing:-1px;
    color: var(--heading-color);
    margin:0 0 0.3em 0;
}

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
}

h3 {
    font-size: 1.2rem;
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color: var(--link-hover-color);
}

.markerLabels{
    overflow:visible !important;
}

.fa-map-marker{
    background: url(/extensions/local/intendit/intenditExt/web/black/map-marker.svg);
    background-size: cover;
    padding: 10px;
    background-position: center;
}


/* Media Queries
---------------------------------------------------------*/
 
@media (max-width: var(--breakpoint-menu)) {
    .menu.show, .menu {
        background:#7F4909 !important;
        box-shadow: 0 4px 2px -2px gray;
    }
    #menu-icon:before, #menu-icon:after, #menu-icon span {
        border-color:#fff;
    }
    .navtext {
        color:white;
    }
}

@media (max-width: var(--breakpoint-s)) {
}

@media (max-width: var(--breakpoint-xs)) {
}
